module.exports = {
  about: {
    text:
      'Bærum Oslo og Asker  is an appraiser business which is located at Frogner in Oslo. The company is affiliated with Norges Takseringforbund and is run by Stian Claudi Reinung and Anders Roestad, which both have an education as carpenters and appraisers. We have many years of experience in the construction industry, with everything from purchase and sale of residences, development of detached residential and larger housing estates. Our goal is to help you with the purchase and sale of properties, refinancing and take overs. We work mainly in parts of eastern Norway.',
    title: 'About us',
  },
  contact: {
    contactUs: 'Please contact us on email or phone for a friendly conversation',
  },
  navigation: {
    about: '@:about.title',
    contact: 'Contact',
    pricelist: 'Price list',
  },
  pricelist: {
    title: 'You can find our price list here: ',
  },
};
