import no from './no';
import en from './en';

const messages = {
  no,
  en,
};

export const LOCALSTORAGE_KEY = 'selected_language';

export default {
  defaultLocale: localStorage.getItem(LOCALSTORAGE_KEY) ?? 'no',
  messages,
};
