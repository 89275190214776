<template>
  <select class="language-selector" name="languages" v-model="selectedLanguage">
    <option v-for="lang in languages" :key="lang.value" :value="lang.value">
      {{ lang.label }}
    </option>
  </select>
</template>

<script>
import { LOCALSTORAGE_KEY } from '@/lang';

export default {
  data: () => ({
    languages: [
      { value: 'en', label: 'English' },
      { value: 'no', label: 'Norsk' },
    ],
    selectedLanguage: '',
  }),
  created() {
    const locale = localStorage.getItem(LOCALSTORAGE_KEY);
    this.selectedLanguage = this.languages.find(
      ({ value }) => value === locale
    ).value;
  },
  watch: {
    selectedLanguage(lang) {
      this.setLocale(lang);
    },
  },
  methods: {
    setLocale(locale) {
      localStorage.setItem(LOCALSTORAGE_KEY, locale);
      this.$i18n.locale = locale;
    },
  },
};
</script>

<style lang="scss">
.language-selector {
  border: none;
  color: #96a18d;
  outline: none;
  option {
    padding: 5px 2px;
  }
}
</style>
