import Vue from 'vue';
import VueI18n from 'vue-i18n';
import lang from '@/lang';

Vue.use(VueI18n);

const vueI18n = new VueI18n({
  locale: lang.defaultLocale, // set locale
  messages: lang.messages // set locale messages
});

export default vueI18n;