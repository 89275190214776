<template>
  <div class="contact">
    <div class="contact-box">
      <img class="contact-image" :src="stian" alt="Stian" />
      <ul>
        <li>Stian Claudi Reinung</li>
        <li>
          <a href="mailto:stian@boatakst.no">stian@boatakst.no</a>
        </li>
        <li><a href="tel:+47 98609961">98609961</a></li>
      </ul>
    </div>
    <div class="contact-box">
      <img class="contact-image" :src="anders" alt="Stian" />
      <ul>
        <li>Anders Roestad</li>
        <li><a href="mailto:anders@boatakst.no">anders@boatakst.no</a></li>
        <li><a href="tel:+47 95815795">95815795</a></li>
      </ul>
    </div>
    <p>{{ $t('contact.contactUs') }}</p>
  </div>
</template>

<script>
import anders from '@/assets/anders.jpg';
import stian from '@/assets/stian.jpg';

export default {
  data: () => ({
    anders,
    stian,
  }),
};
</script>

<style lang="scss">
.contact {
  display: grid;
  padding: 10px;
  grid-template-columns: auto auto;
  .contact-box {
    display: flex;
    ul {
      list-style: none;
      line-height: 1.8em;
      padding-left: 5px;

      a {
        color: #96a18d;
      }
    }
  }
  .contact-image {
    width: 100px;
    border-radius: 8px;
  }
  @media only screen and (max-width: 1000px) {
    grid-gap: 0;
    grid-template-columns: none;
    img {
      padding: 5px 0;
    }
  }
}
</style>
