import Vue from 'vue';
import VueRouter from 'vue-router';
import About from '@/views/About';
import Contact from '@/views/Contact';
import Pricelist from '@/views/Pricelist';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: { name: 'about' },
  },
  {
    path: '/om-oss',
    name: 'about',
    component: About,
  },
  {
    path: '/kontakt-oss',
    name: 'contact',
    component: Contact,
  },
  {
    path: '/prisliste',
    name: 'pricelist',
    component: Pricelist,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
