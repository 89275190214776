<template>
  <router-link
    active-class="underline-active"
    :to="{ name: title }"
    class="nav-item"
  >
    <font-awesome-icon :icon="icon" />
    <span>{{ $t(`navigation.${title}`) }}</span>
  </router-link>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  color: #96a18d;
  i {
    height: 20px;
    width: 20px;
  }
}
.underline-active {
  border-bottom: #96a18d 4px solid;
}
</style>
