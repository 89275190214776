import Vue from 'vue';
import Vuex from 'vuex';

import settingsData from '@/vuex/settings-data';

Vue.use(Vuex);

const vuex = new Vuex.Store({
  modules: {
    settingsData,
  }
});

export default vuex;