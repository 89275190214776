<template>
  <div id="app">
    <div class="main">
      <Navigation />
      <div class="image-container">
        <img :src="headerImage" class="header-image" />
      </div>
      <div class="content-container">
        <router-view />
      </div>
    </div>
    <!-- <div class="footer">
      <Footer />
    </div> -->
  </div>
</template>

<script>
// import Footer from '@/components/Footer';
import Navigation from '@/components/Navigation';
import headerImage from '@/assets/boa-header-image.jpg';

export default {
  components: {
    // Footer,
    Navigation,
  },
  data: () => ({
    headerImage,
  }),
};
</script>

<style lang="scss">
body {
  overflow-x: hidden;
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  line-height: 1.5rem;

  .main {
    width: 100%;
  }

  .image-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .header-image {
    top: 60px;
    width: 100%;
    max-width: 1200px;
    height: 500px;
    margin: 0 auto;
  }
  .content-container {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    top: 400px;
    left: 0;
    right: 0;
    background-color: white;
    border-radius: 5px;
    width: 50vw;
    max-width: 1100px;
    border: 0.5px solid #727272;
  }

  @media only screen and (max-width: 600px) {
    .content-container {
      top: 150px;
      width: 90vw;
    }
  }
}
</style>
