module.exports = {
  about: {
    text:
      'Bærum Oslo og Asker Takst er et takserings foretak med beliggenhet på Frogner i Oslo. Selskapet er tilknyttet Norges Takseringforbund og er drevet av Stian Claudi Reinung og Anders Roestad. Begge har utdanning som byggmester og takstmann, og vi har mange års erfaring i byggebransjen, med alt fra kjøp og salg av bolig, rehabilitering av bolig, oppsetting av eneboliger og utbygging av større boligfelt. Vi har som mål å hjelpe mennesker med kjøp og salg av eiendom, bygge-oppfølging, refinansiering og overtakelser. Vi jobber i store deler av Østlandet med hovedfelt I Bærum, Oslo og Asker.',
    title: 'Om oss',
  },
  contact: {
    contactUs: 'Vennligst kontakt oss på mail eller telefon for en hyggelig samtale'
  },
  navigation: {
    about: '@:about.title',
    contact: 'Kontakt',
    pricelist: 'Prisliste',
  },
  pricelist: {
    title: 'Du kan finne vår prisliste her: ',
  },
};
