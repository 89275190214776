import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuex from '@/plugins/vuex';
import i18n from '@/plugins/vue-i18n';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faExclamation,
  faPhone,
  faList,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faExclamation);
library.add(faPhone);
library.add(faList);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  store: vuex,
  render: (h) => h(App),
}).$mount('#app');
