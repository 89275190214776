<template>
  <div class="navigation-wrapper">
    <div class="navigation-bar">
      <div class="content">
        <img @click="goToAbout" alt="Boa logo" height="50" :src="boaLogo" />
        <div class="navigation-items" v-if="isMobile">
          <NavigationItem
            v-for="nav in navigationItems"
            :key="nav.title"
            :icon="nav.icon"
            :title="nav.title"
          />
          <LanguageSelector />
        </div>
        <div class="navigation-items" v-else>
          <LanguageSelector />
          <BurgerMenu @toggleMobileMenu="toggleMobileMenu" />
        </div>
      </div>
    </div>
    <div class="mobile-routes" v-if="showMobileMenu">
      <router-link
        v-for="nav in navigationItems"
        :to="{ name: nav.title }"
        :key="nav.title"
        >{{ $t(`navigation.${nav.title}`) }}</router-link
      >
    </div>
  </div>
</template>

<script>
import BurgerMenu from '@/components/BurgerMenu';
import LanguageSelector from '@/components/LanguageSelector';
import NavigationItem from '@/components/NavigationItem';
import boaLogo from '@/assets/boa-logo.png';

export default {
  components: {
    BurgerMenu,
    LanguageSelector,
    NavigationItem,
  },
  data: () => ({
    boaLogo,
    navigationItems: [
      {
        icon: 'exclamation',
        title: 'about',
      },
      {
        icon: 'phone',
        title: 'contact',
      },
      {
        icon: 'list',
        title: 'pricelist',
      },
    ],
    showMobileMenu: false,
  }),
  computed: {
    isMobile() {
      return window.screen.width > 550;
    },
  },
  methods: {
    goToAbout() {
      this.$router.push({ name: 'about' });
    },
    toggleMobileMenu(val) {
      this.showMobileMenu = val;
    },
  },
};
</script>

<style lang="scss">
$nav-height: 60px;
.navigation-wrapper {
  position: relative;
  .navigation-bar {
    background-color: #fcfcfc;
    padding-top: 8px;
    height: $nav-height;
    width: 100vw;
    .content {
      display: flex;
      align-content: center;
      justify-content: space-between;
      width: 80vw;
      max-width: 1200px;
      margin: 0 auto;
      img {
        cursor: pointer;
      }
    }
    .navigation-items {
      display: flex;
      align-content: center;
    }
  }
  .mobile-routes {
    position: absolute;
    top: $nav-height;
    height: 40px;
    width: 100%;
    border-top: 0.5px solid #727272;
    border-bottom: 0.5px solid #727272;
    background-color: #fcfcfc;
    font-size: 1.2rem;
    display: flex;
    justify-content: space-evenly;
    a {
      color: #96a18d;
      margin-top: 8px;
    }
  }
}
</style>
