<template>
  <button @click="toggleMobileMenu" class="burger-nav-menu">
    <div class="line" />
    <div class="line" />
    <div class="line" />
  </button>
</template>

<script>
export default {
  data: () => ({
    showMenu: false,
  }),
  methods: {
    toggleMobileMenu() {
      console.log('clicking');
      this.showMenu = !this.showMenu;
      this.$emit('toggleMobileMenu', this.showMenu);
    },
  },
};
</script>
<style lang="scss">
.burger-nav-menu {
  border: 0;
  padding: 0;
  background-color: inherit;
  margin-left: 10px;
  width: 40px;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  .line {
    height: 4px;
    background-color: #96a18d;
    width: 100%;
  }
}
</style>
